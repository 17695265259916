import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Iframe from "react-iframe"

import Layout from "../../../components/common/layout/layout"

import fitness from "../../../images/icons/Icon/Fitness.png"
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image'


const query = graphql`
  query {
    allStrapiStore {
      edges {
        node {
          storeName
          storeLink
          storeDescription
          storeCategory
          storeImage {
            childImageSharp {
              fixed(width: 320, height: 240) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;

const DFitness = () => (
  <Layout>
    <CategoryContainer>
        <Icon src={fitness}></Icon>
        <CategoryHeader>Health and Fitness</CategoryHeader>
        <CategoryText>Want to join a physiotherapy regimen or the 15 day wellness trip to your favourite resort. Don’t postpone such essential and not so essential things in life. Pay through a dili loan and fulfil your dream now.
        <br />
        <br />
        Join the most happening Gym and get into peak fitness without worrying about how to pay for it. Take a dili loan and get on to that treadmill.
        <br />
        <br />
        <ul>
            <li>Gym and Health Club memberships</li>
            <li>Yoga and wellness programs</li>
            <li>Nutrition and special programs</li>
            <li>Alternative therapy for chronic ailments</li>
            <li>Physiotherapy, acupressure, acupuncture and other long term regimens</li>
        </ul>
        </CategoryText>
    
    <Spacing />
    <Stores>
        <StaticQuery
            query={query}
            render={data => (
                <StoreC>
                    {data.allStrapiStore.edges.filter(store => store.node.storeCategory === "healthandfitness").map(store => (
                        <Store>
                            <StoreImg fixed={store.node.storeImage.childImageSharp.fixed}/>
                            <StoreHeader>{store.node.storeName}</StoreHeader>
                            <StoreText>{store.node.storeDescription}</StoreText>
                            <Link to={store.node.storeLink}>
                                <StoreButton>Shop now</StoreButton>
                            </Link>
                        </Store>
                    ))}
                </StoreC>
            )}
        />
    </Stores>
    <Spacing />
    <FormText>
    <FormHeader>
        Don’t see what you want to buy here? Help us curate a loan for you.
    </FormHeader>
    <FormSubheader>
        At dili, we are constantly looking to serve our customers better. If your requirement does not fit in the categories listed above, or the merchant you wish to buy from is not currently signed up with dili, tell us about it and we will try to curate a loan for you. 
    </FormSubheader>
    </FormText>
    <FormContainer>
        <FormEmbed src="https://docs.google.com/forms/d/e/1FAIpQLScbyaRY2G20xafoCvqNfw_37ZlsT9-bR63qV-LoW__eiKKkig/viewform?embedded=true" width="640" height="640" frameBorder="0">
            Loading...
        </FormEmbed>
    </FormContainer>
    </CategoryContainer>
  </Layout>
)

export default DFitness


export const Spacing = styled.div`
    margin-bottom: 120px;
`

export const CategoryContainer = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
`

export const CategoryHeader = styled.div`
    color: #142630;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-right: 40px;
`

export const CategoryText = styled.div`
    color: #465F6D;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-top: 20px;
    width: 70%;
`

export const Icon = styled.img`
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
`

export const Stores = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const Store = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    margin-bottom: 80px;
`

export const StoreImg = styled(Img)`
    margin-bottom: 16px;
    border-radius: 20px;
`

export const StoreHeader = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    width: 320px;
`

export const StoreText = styled.div`
    font-size: 18px;
    color: grey;
    margin-bottom: 16px;
    width: 320px;
    line-height: 1.3;
`

export const StoreButton = styled.button`
    background: black;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 96px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 40px;
`

const FormText = styled.div`
    flex-direction: column;
    margin-top: 120px;
`

const FormHeader = styled.div`
    font-size: 36px;
    font-weight: bold;
    color: #142630;
    width: 60%;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 40px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
        width: 80%;
    }
`

const FormSubheader = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    width: 60%;
    font-size: 20px;
    color: #465F6D;
    line-height: 1.5;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 480px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 360px;
        font-size: 14px;
    }
`

const FormContainer = styled.div`
    display: flex;
    padding-top: 24px;
    padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
    width: 640px;
    height: 640px;
`

export const StoreC = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`